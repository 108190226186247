body {
    background-color: #fafafa;
}

.text-danger {
    color: red !important;
}

.text-center {
    text-align: center;
}

.w-100 {
    width: 100%;
}

.minw-100 {
    min-width: 100%;
}

.login-card {
    max-width: 500px;
    margin: auto;
    margin-top: 10%;
}

.login-card .btn {
    width: 100%;
}

.d-block {
    display: block;
}

.card {
    color: black;
    text-decoration: none !important;
}

.preview {
    width: 100%;
}